<template>
  <div class="project">
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        v-model="activeKey"
        :tabBarStyle="{
          margin: '0',
        }"
        @change="handelTabChange"
      >
        <a-tab-pane key="0" tab="组织机构建立"></a-tab-pane>
        <a-tab-pane key="1" tab="人员分配"></a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <div v-show="activeKey === '0'">
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
          :colon="false"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="当前项目">
                <a-input
                  v-decorator="[
                    'designCode',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入！',
                        },
                      ],
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="管理类别">
                <a-radio-group
                  v-decorator="[
                    'type',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择！',
                        },
                      ],
                    },
                  ]"
                >
                  <a-radio
                    v-for="item in epcTypeList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入！',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="情况说明" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-textarea
                  :auto-size="{ minRows: 5 }"
                  v-decorator="[
                    'remark',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入！',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <span slot="label" class>附件</span>
                <div class="link-list" v-decorator="['file']">
                  <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
                    <div class="link" v-for="item in fileList" :key="item.id">
                      <a-checkbox :value="item.id">
                        <a target="_blank" download :href="item.completePath">
                          {{
                          item.name
                          }}
                        </a>
                      </a-checkbox>
                    </div>
                  </a-checkbox-group>

                  <div class="control-bar">
                    <FileUpload @uploaded="uploaded">
                      <div class="control">
                        <a-icon type="upload" />
                      </div>
                    </FileUpload>

                    <div class="control" @click="deleteFile">
                      <a-icon type="delete" />
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="center">
            <a-space>
              <a-button @click="$close($route.path)">关闭</a-button>
              <a-button htmlType="submit" type="primary">下一步</a-button>
            </a-space>
          </div>
        </a-form>
      </div>

      <div v-show="activeKey === '1'">
        <div class="grid-container">
          <div
            class="card"
            v-for="(item, index) in memberList"
            :key="index"
            :class="item.tempList.length > 1 ? 'full' : '' "
          >
            <a-row
              v-if="item.tempList.length > 1"
              style="margin: 0 10px; background-color: #f8f8f8;padding-bottom: 8px"
            >
              <a-button type="primary">{{ item.deptName }}</a-button>
              <div v-if="Array.isArray(item.tempList)">
                <a-col
                  :lg="8"
                  :md="8"
                  :sm="24"
                  v-for="(element, idx) in item.tempList"
                  :key="element.role"
                >
                  <template>
                    <a-form-item>
                      <div class="member-form">
                        <DataDictFinder
                          class="left"
                          dictType="epc_prj_member_role"
                          :dictValue="element.role"
                        />
                        <MemberSelecor
                          class="member-right"
                          :item="element"
                          @change="(value) => onChange(index, idx, value)"
                        ></MemberSelecor>
                      </div>
                    </a-form-item>
                  </template>
                </a-col>
              </div>
            </a-row>
            <div v-else>
              <div
                v-for="(element, idx) in item.tempList"
                :key="element.role"
                style="
                    background-color: #f8f8f8;
                    padding-bottom: 8px;
                    border: 10px solid #fff;
                  "
              >
                <a-button type="primary">{{ item.deptName }}</a-button>
                <a-form-item>
                  <div class="member-form">
                    <DataDictFinder
                      class="left"
                      dictType="epc_prj_member_role"
                      :dictValue="element.role"
                    />
                    <MemberSelecor
                      class="member-right"
                      :item="element"
                      @change="(value) => onChange(index, idx, value)"
                    ></MemberSelecor>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button @click="activeKey = '0'">上一步</a-button>
            <a-button @click="editForm" type="primary">提交</a-button>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { edit, fetchDetail, fetchPrjMemberList } from "@/api/epc";
import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";
import MemberSelecor from "./component/member-selector.vue";

export default {
  name: "projUpdate",
  components: {
    FileUpload,
    MemberSelecor
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      activeKey: "0",
      form: this.$form.createForm(this),
      id: "",

      fileList: [],
      selectedFileList: [],

      memberList: [] // 部门下角色列表
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isEpcManager"]),

    epcTypeList() {
      return this.findDataDict("epc_prj_type");
    }
  },
  activated() {
    const { query } = this.$route;

    const { id, activeKey } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.activeKey = activeKey || "0";

      this.fileList = [];
      this.selectedFileList = [];
      this.memberList = [];

      this.getDetail();
      this.getMemberList();
    }
  },
  methods: {
    handelTabChange(key) {
      if (key === "1") {
        this.form.validateFields(err => {
          if (err) {
            this.$message.error("请完整建立组织机构");
            this.activeKey = "0";
          }
        });
      }
    },

    getDetail() {
      fetchDetail(this.id).then(res => {
        if (res) {
          this.form.setFieldsValue({
            designCode: res.designCode,
            name: res.name,
            remark: res.remark,
            type: res.type,
            file: res.attachmentList
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        }
      });
    },
    getMemberList() {
      fetchPrjMemberList({ pageNum: 1, pageSize: 1000, pid: this.id })
        .then(res => {
          if (Array.isArray(res.list)) {
            const deptList = [...new Set(res.list.map(item => item.deptName))];
            this.memberList = deptList.map(item => {
              const arr = [];
              res.list.forEach(element => {
                if (element.deptName === item) {
                  arr.push({
                    ...element
                  });
                }
              });
              return {
                deptName: item,
                tempList: arr
              };
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.activeKey = "1";
        } else {
          this.$message.error("请完整建立组织机构！");
        }
      });
    },

    editForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let members = [];

          this.memberList.forEach(item => {
            if (Array.isArray(item.tempList)) {
              item.tempList.forEach(element => {
                members.push(element);
              });
            }
          });

          edit({
            id: this.id,
            ...values,
            attachments: this.fileList,
            members
          }).then(() => {
            this.$close(this.$route.path);
          });
        } else {
          this.$message.error("请建立组织机构");
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach(item => {
        const index = this.fileList.findIndex(file => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
    // 修改员工
    onChange(index, idx, value) {
      const list = this.memberList[index].tempList;
      const item = list[idx];
      item.userName = value.name;
      item.userId = value.userId;
      list.splice(idx, 1, item);
      this.memberList.splice(index, 1, {
        ...this.memberList[index],
        tempList: list
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
  .card {
    margin-bottom: 20px;
    .ant-btn {
      margin-bottom: 15px;
      width: 130px;
    }
    .member-form {
      display: flex;
      padding: 0 10px;
      .member-right {
        margin-left: 10px;
        width: 65%;
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .full {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.center {
  padding: 80px 0;
}
</style>

<style lang="less">
.project {
  .left span {
    width: 60px !important;
  }
}
</style>
